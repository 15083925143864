<template>
  <div>
    <base-section
      id="enduser-account"
    >
      <v-container>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="12"
          >
            <base-info-card :title="$t('AccountEU.mainTitle')" />
            <v-card
              class="rounded-xl"
            >
              <kdw-title-card-system-bar
                color="jean"
                icon="mdi-card-account-details-outline"
                title-tag="AccountEU.mainTitle"
              />
              <v-card-text
                class="mt-2"
              >
                <v-form>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <kdw-text-field
                        id="IDfirstname"
                        v-model="myPerson.firstname"
                        :label="$t('identity.firstname') + $t('meta.star')"
                        prepend-icon="mdi-account-circle"
                        clearable
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <kdw-text-field
                        id="IDlastname"
                        v-model="myPerson.lastname"
                        :label="$t('identity.lastname') + $t('meta.star')"
                        prepend-icon="mdi-account-circle"
                        clearable
                      />
                    </v-col>
                  </v-row>
                  <kdw-address
                    v-model="myPerson.address"
                    :display="trickUpdateAddress"
                  />
                  <v-row>
                    <v-col
                      cols="12"
                      sm="4"
                    >
                      <kdw-vue-tel-input
                        v-model="myPerson.phone"
                        @internationalPhoneNumber="internationalPhoneNumberFct($event)"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="8"
                    >
                      <kdw-text-field-email
                        id="IDmail"
                        v-model="myPerson.email"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-checkbox
                      id="IDconsent"
                      v-model="myPerson.consent"
                    >
                      <template #label>
                        <kdw-legal-sentence />
                      </template>
                    </v-checkbox>
                  </v-row>
                  <v-row
                    class="mr-1 mb-1"
                    justify="end"
                  >
                    <kdw-btn
                      id="IDsubmit"
                      @click="submit"
                    >
                      {{ $t('button.validateQuestion') }}
                    </kdw-btn>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'
  import { updateEndUser as updateEndUserMutation } from '@/graphql/mutations'
  import { getEndUser } from '@/graphql/queries'
  import { API, Logger } from 'aws-amplify'
  import { getUserSub } from '@/services/User'
  import { navigate } from '@/helpers/Navigation'

  const logger = new Logger('EndUserAccount')

  @Component({ name: 'EndUserMyAccount' })
  class EndUserMyAccount extends Vue {
    alertSnackBarMessageMAXTIME = 10000
    myPerson = {
      id: '',
      firstname: '',
      lastname: '',
      address: {
        address1: '',
        address2: '',
        postcode: '',
        cityId: '',
        countryId: '',
      },
      phone: '',
      phoneInt: '',
      email: '',
      consent: false,

    }

    trickUpdateAddress = 0

    internationalPhoneNumberFct (internationPhoneNumber) {
      // console.log('intPhoneNumber:', internationPhoneNumber)
      this.myPerson.phoneInt = internationPhoneNumber
    }

    // click on the submit button
    submit () {
      if (!this.myPerson.id || !this.myPerson.firstname || !this.myPerson.lastname || !this.myPerson.email) return
      this.updateEndUserData(this.myPerson)
    }

    // Before loading the form but also called during the form exit!!!!
    created () {
      // console.log('created in acconut')
      this.loadEndUserData(this.myPerson)
        .then(() => {
          // console.log('data of end user loaded')
        })
    }

    async loadEndUserData (myEndUser) {
      // console.log('loadEndUserData start')
      // console.log('end User: ' + await getUserSub())
      try {
        const apiDataEndUser = await API.graphql({ query: getEndUser, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        // console.log(apiDataEndUser)
        const EndUser = apiDataEndUser.data.getEndUser
        // console.log(EndUser)
        if (EndUser.length === 0) {
          this.$root.kdwsnackbar.alert('AccountEU.alertEUNoFound')
          // alert(this.$i18n.t('AccountEU.alertEUNoFound'))
          return
        }
        // change the data by reference (can not pass the object)
        myEndUser.firstname = EndUser.firstname
        myEndUser.lastname = EndUser.lastname
        myEndUser.address.address1 = EndUser.address1
        myEndUser.address.address2 = EndUser.address2
        myEndUser.address.postcode = EndUser.postcode
        // console.log(EndUser.postcodecity)
        if (EndUser.postcodecity === null) {
          // console.log('found null')
          myEndUser.address.cityId = ''
        } else {
          myEndUser.address.cityId = EndUser.postcodecity.id
        }
        myEndUser.phone = EndUser.phone
        myEndUser.phoneInt = EndUser.phone
        myEndUser.email = EndUser.email
        myEndUser.consent = EndUser.consent
        myEndUser.id = EndUser.id
        if (EndUser.country !== null) {
          myEndUser.address.countryId = EndUser.country.id
        }
        this.trickUpdateAddress++
        // console.log('loadEndUserData end')
      } catch (err) {
        logger.error(err, err.stack)
        this.$root.kdwsnackbar.alert('AccountEU.alertEULoadDataIssue', this.alertSnackBarMessageMAXTIME)
        // alert(this.$i18n.t('AccountEU.alertEULoadDataIssue'))
      }
    }

    async updateEndUserData (endUserToUpdate) {
      try {
        // console.log('endUserToUpdate : ')
        // console.log(endUserToUpdate)
        const objectEndUserToUpdate = {
          id: endUserToUpdate.id,
          firstname: endUserToUpdate.firstname,
          lastname: endUserToUpdate.lastname,
          address1: endUserToUpdate.address.address1,
          address2: endUserToUpdate.address.address2,
          postcode: endUserToUpdate.address.postcode,
          endUserPostcodecityId: endUserToUpdate.address.cityId,
          endUserCountryId: endUserToUpdate.address.countryId,
          phone: endUserToUpdate.phoneInt,
          email: endUserToUpdate.email,
          consent: endUserToUpdate.consent,
        }
        await API.graphql({ query: updateEndUserMutation, variables: { input: objectEndUserToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        this.$root.kdwsnackbar.alert('AccountEU.alertEUSuccessUpdate')
        // We move automatically to the myServices pages after 1.5s.
        setTimeout(() => {
          navigate(this, 'EndUserMyServices')
        }, 1500)
      } catch (err) {
        logger.error(err)
        this.$root.kdwsnackbar.alert('AccountEU.alertEUFailedUpdate', -1)
      }
    }
  }

  export default EndUserMyAccount
</script>
